import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const Success = () => {
  return (
    <div>
      <FormSuccessTemplate
        title="Succès - Demande d'accompagnement"
        messageSubContent='Vous allez recevoir un email de confirmation. Pensez à regarder vos spams.'
        description="Votre demande d'accompagnement a bien été déposée sur notre plateforme TrouverMonLocalPro !" />
    </div>
  )
}

export default Success
